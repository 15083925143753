import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e7d3096e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-wr" }
const _hoisted_2 = { class: "header-mobile" }
const _hoisted_3 = { class: "top-row" }
const _hoisted_4 = { class: "buttons" }
const _hoisted_5 = { class: "header-desktop" }
const _hoisted_6 = { class: "header-controls" }
const _hoisted_7 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: "logo",
          to: "/"
        }),
        _createElementVNode("div", _hoisted_4, [
          ($setup.showLogin)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "link-primary",
                onClick: $setup.onLogin
              }, "Log in"))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("header", _hoisted_5, [
      _createVNode(_component_router_link, {
        class: "logo",
        to: "/"
      }),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          ($setup.showLogin)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "link-primary",
                onClick: $setup.onLogin
              }, "Log in"))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode($setup["LoginModal"], {
      "is-open": $setup.isOpenedLoginModal,
      onClose: $setup.onCloseLoginModal,
      onJoin: _cache[0] || (_cache[0] = () => {}),
      onLogin: $setup.signIn,
      "login-error": $setup.loginError,
      onForgotPassword: $setup.onForgotPassword
    }, null, 8, ["is-open", "login-error"]),
    _createVNode($setup["ResetPasswordModal"], {
      "is-open": $setup.isOpenedResetPasswordModal,
      onOnSend: $setup.onSendInResetPasswordModal,
      onClose: $setup.onCloseResetPasswordModal,
      message: $setup.forgotPasswordMessage,
      "is-clear-form": $setup.clearForm
    }, null, 8, ["is-open", "message", "is-clear-form"])
  ]))
}