import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/arrow-right-landing.svg'


const _withScopeId = n => (_pushScopeId("data-v-764c9914"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-aos", "target"]
const _hoisted_2 = {
  key: 0,
  class: "button-contact-us--icon",
  src: _imports_0
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "button-contact-us",
    onClick: $setup.onClick,
    "data-aos": $setup.props.animationName,
    target: $setup.props.toContactUsForm ? '_self' : '_blank'
  }, [
    _createTextVNode(_toDisplayString($setup.props.text), 1),
    (!$setup.props.isButtonCallBackModal)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}